import { AUTH_TYPE, METHODS } from "../auth";

const AUTHENTICATION = "authentication";
const REQUEST = "request";
const INTEGRATION_CONFIG_KEYS = {
  AUTHENTICATION,
  REQUEST
};

export const CUSTOM_INTEGRATION_RAW_DEFAULT = {
  [INTEGRATION_CONFIG_KEYS.AUTHENTICATION]: {
    enabled: true,
    type: AUTH_TYPE.OAUTH_20,
    method: METHODS.POST.toUpperCase(),
    url: "",
    headers: [],
    payload: [],
    error: {
      schema: null
    },
    response: {
      schema: null
    }
  },
  [INTEGRATION_CONFIG_KEYS.REQUEST]: {
    enabled: true,
    method: METHODS.GET.toUpperCase(),
    url: "",
    headers: [],
    payload: [],
    error: {
      schema: null
    },
    response: {
      schema: null
    }
  }
};
