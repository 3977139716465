import css from "styled-jsx/css";

export const getOverrideStyles = (theme) => {
  return css.resolve`
    :global(.rc-checkbox) {
      width: 20px;
      height: 20px;
      white-space: nowrap;
      cursor: pointer;
      outline: none;
      display: inline-block;
      position: relative;
      line-height: 1;
      vertical-align: middle;
    }
    :global(.rc-checkbox-input) {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      z-index: 9999;
      cursor: pointer;
      opacity: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }
    :global(.rc-checkbox-inner) {
      position: relative;
      top: 0;
      left: 0;
      display: inline-block;
      width: 18px;
      height: 18px;
      border-width: 1px;
      border-style: solid;
      border-radius: 3px;
      border-color: #d9d9d9;
      background-color: #ffffff;
      -webkit-transition: border-color 0.3s
          cubic-bezier(0.68, -0.55, 0.27, 1.55),
        background-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
      transition: border-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55),
        background-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    }
    :global(.rc-checkbox-checked .rc-checkbox-inner) {
      border-color: ${theme["color-primary"]};
      background-color: ${theme["color-primary"]};
    }
    :global(.rc-checkbox-inner:after),
    :global(.rc-checkbox-checked .rc-checkbox-inner:after) {
      webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      left: 6px;
      top: 1px;
      display: table;
      width: 4px;
      height: 10px;
      border: 3px solid #ffffff;
      border-top: 0;
      border-left: 0;
      content: " ";
      -webkit-animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
      animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
      -webkit-animation-duration: 0.3s;
      animation-duration: 0.3s;
      -webkit-animation-name: amCheckboxOut;
      animation-name: amCheckboxOut;
    }
    :global(.rc-checkbox:hover .rc-checkbox-inner, .rc-checkbox-input:focus
        + .rc-checkbox-inner) {
      border-color: ${theme["color-primary"]};
      border-top-color: ${theme["color-primary"]};
      border-right-color: ${theme["color-primary"]};
      border-bottom-color: ${theme["color-primary"]};
      border-left-color: ${theme["color-primary"]};
    }
  `;
};
