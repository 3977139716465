import css from "styled-jsx/css";
import { PHONE } from "utils/constants/ui";
import { COLORS } from "utils/styles";

export const getFormStyles = (theme) => {
  return css.resolve`
    :global(.react-datepicker) {
      font-family: ${theme["font-family"]}, "Avenir Next", sans-serif;
    }

    :global(.PhoneInputCountrySelectArrow) {
      display: none;
    }

    :global(.phoneCountrySelect--disabled) {
      min-height: 44px;
      height: 44px;
      width: 37px;
      z-index: 2;
      cursor: not-allowed;
      background: ${COLORS.disabled};
      box-shadow: 0 0 0 1px #e0e0e0,0 2px 4px 0 rgba(0,0,0,.07),0 1px 1.5px 0 rgba(0,0,0,.05);
      transition: box-shadow .08s ease-in,color .08s ease-in,filter 50000s;
    }

    @media (min-width: ${PHONE + 1}px) {
      :global(.phoneCountrySelect--disabled) {
        min-height: 36px;
        height: 36px;
        width: 37px;
      }
    }

    :global(input.input--muted),
    :global(input.input--muted:focus) {
      outline: none;
      box-shadow: none !important;
    }
    
    :global(input.PhoneInputInput),
    :global(.input--common),
    :global(select.input--common),
    :global(input.input--common),
    :global(textarea.input--common), {
        min-height: 44px;
        height: 44px;
        font-size: 16px;
        box-sizing: border-box;
        position: relative;
        width: 100%;
        padding: 8px 12px;
        line-height: 1.5;
        border: 0;
        box-shadow: 0 0 0 1px #e0e0e0,0 2px 4px 0 rgba(0,0,0,.07),0 1px 1.5px 0 rgba(0,0,0,.05);
        transition: box-shadow .08s ease-in,color .08s ease-in,filter 50000s;
        background: #fff;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    
    :global(.input--common__simulated) {
        box-sizing: border-box;
        position: relative;
        width: 100%;
        padding: 8px 12px;
        line-height: 1.5;
        border: 0;
        box-shadow: 0 0 0 1px #e0e0e0,0 2px 4px 0 rgba(0,0,0,.07),0 1px 1.5px 0 rgba(0,0,0,.05);
        transition: box-shadow .08s ease-in,color .08s ease-in,filter 50000s;
        background: #fff;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    :global(input.PhoneInputInput:disabled),
    :global(.input--common__simulated.disabled),
    :global(.input--common:disabled),
    :global(select.input--common:disabled),
    :global(input.input--common:disabled),
    :global(textarea.input--common:disabled), {
      background: ${COLORS.disabled};
    }
    @media (min-width: ${PHONE + 1}px) {
      :global(input.PhoneInputInput),
      :global(select.input--common),
      :global(input.input--common),
      :global(textarea.input--common), {
      min-height: 36px;
      height: 36px;
      font-size: 14px;
      box-sizing: border-box;
      position: relative;
      width: 100%;
      padding: 8px 12px;
      line-height: 1.5;
      border: 0;
      box-shadow: 0 0 0 1px #e0e0e0,0 2px 4px 0 rgba(0,0,0,.07),0 1px 1.5px 0 rgba(0,0,0,.05);
      transition: box-shadow .08s ease-in,color .08s ease-in,filter 50000s;
      background: #fff;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      }
    }

    :global(.multiselect__control) {
      font-size: 16px !important;
      z-index: 2;
      min-height: 44px !important;
    }
    :global(.multiselect--autoHeight__control) {
      font-size: 16px !important;
      z-index: 2;
    }

    :global(.multiselect__control--is-focused),
    :global(.multiselect--autoHeight__control--is-focused) {
      outline: none;
      background: ${COLORS.whitesmoke};
      box-shadow: 0 0 0 1px rgb(50 151 211 / 30%), 0 1px 1px 0 rgb(0 0 0 / 7%), 0 0 0 4px rgb(50 151 211 / 30%) !important;
    }
    
    :global(.multiselect__single-value),
    :global(.multiselect__placeholder),
    :global(.multiselect--autoHeight__single-value),
    :global(.multiselect--autoHeight__placeholder) {
      margin-left: 0px !important;
    }

    :global(.multiselect__menu),
    :global(.multiselect--autoHeight__menu) {
      z-index: 4;
    }
    
    @media (min-width: ${PHONE + 1}px) {
      :global(.multiselect__control) {
        font-size: 14px !important;
        min-height: 36px !important;
      }
      :global(.multiselect--autoHeight__control) {
        font-size: 14px !important;
      }
    }

    :global(.multiselect__value-container) {
        max-height: 44px;
    }

    :global(.multiselect--autoHeight__value-container) {
      padding-top: 6px !important;
      padding-bottom: 6px !important;
    }

    :global(.multiselect__input > input:focus),
    :global(.multiselect--autoHeight__input > input:focus) {
      box-shadow: none !important;
    }
    
    @media (min-width: ${PHONE + 1}px) {
      :global(.multiselect__value-container) {
        max-height: 36px;
      }
    }

    :global(input:focus),
    :global(input.PhoneInputInput:focus),
    :global(textarea:focus),
    :global(select:focus),
    :global(.outline-0:focus),
    :global(.PhoneInput.PhoneInput--focus > .PhoneInputCountry),
    :global(select.PhoneInputCountrySelect:focus) {
        outline: none;
        background: ${COLORS.whitesmoke};
        box-shadow: 0 0 0 1px rgb(50 151 211 / 30%), 0 1px 1px 0 rgb(0 0 0 / 7%), 0 0 0 4px rgb(50 151 211 / 30%) !important;
    }
    :global(input.read-only) {
        caret-color: "transparent",
        user-select: "none"
    }

    :global(input.PhoneInputInput) {
        padding: 8px;
        border: 0;
        border-radius: 6px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    
      :global(.PhoneInputCountry) {
        background: white;
        height: 44px;
        font-size: 16px;
        padding: 8px 12px;
        border-radius: 6px;
        margin-right: 0;
        border: 0;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%);
      }
    
      @media (min-width: ${PHONE + 1}px) {
        :global(.PhoneInputCountry) {
          height: 36px;
          font-size: 14px;
        }
      }

      :global(input:not([type="checkbox"]):not([type="radio"])) {
        -webkit-appearance: none;
      }
      :global(input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration) {
        -webkit-appearance: none;
      }
      :global(.field__label) {
        font-size: 13px;
        margin-bottom: 6px;
      }
      @media (min-width: ${PHONE + 1}px) {
        :global(.field__label) {
          font-size: 14px;
          margin-bottom: 6px;
        }
      }
      :global(.field__sublabel) {
        font-size: 12px;
        margin-top: 6px;
      }
      @media (min-width: ${PHONE + 1}px) {
        :global(.field__sublabel) {
          font-size: 12px;
          margin-top: 6px;
        }
      }
      @media (min-width: ${PHONE + 1}px) {
        :global(.form__content--paddingTop-ns) {
          padding-top: 146px;
          padding-bottom: 166px;
        }
      }
      :global(.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover) {
        background: #4990e2;
        border-width: 1px;
        border-style: solid;
        border-color: #4990e2;
        color: #fff;
      }
      :global(.react-toggle-thumb) {
        box-shadow: none !important;
      }
      :global(input::-webkit-date-and-time-value) {
        text-align: left;
      }
      :global(.DateInput_input__focused) {
        outline: 0;
        background: #fff;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 2px:
        border-style: solid;
        border-color: #4990e2;
      }
  `;
};
