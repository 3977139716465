import css from "styled-jsx/css";
import { COLORS } from "utils/styles";

export const getBlocksStyles = (theme) => {
  return css.resolve`
    // =========== BLOCKS
    :global(.BLOCKS__layout) {
      width: 100%;
      color: ${theme["color-text--primary"]};
    }
    :global(.BLOCKS__color-text--primary) {
      color: ${theme["color-text--primary"]};
    }
    :global(.BLOCKS__color-text--secondary) {
      color: ${theme["color-text--secondary"]};
    }
    :global(.BLOCKS__color-primary) {
      color: ${theme["color-primary"]};
    }
    :global(.BLOCKS__feature__enabled--checkmark) {
      color: ${theme["bg-primary"]};
    }
    :global(.BLOCKS__color-error) {
      color: ${theme["color-error"]};
    }
    :global(.BLOCKS__bg-primary) {
      background-color: ${theme["bg-primary"]};
    }
    :global(.BLOCKS__bg-primary--hover) {
      background-color: ${theme["bg-primary--hover"]};
    }
    :global(.BLOCKS__bg-primary--fade) {
      background-color: ${theme["bg-primary--fade"]};
    }
    :global(.BLOCKS__ba--primary) {
      border-color: ${theme["b-primary"]};
      border-width: 1px;
      border-style: solid;
    }
    :global(.BLOCKS__ba--primary--dotted:hover:after) {
      border: dotted 2px ${theme["b-primary"]};
    }
    :global(.BLOCKS__pb-component:after) {
      pointer-events: none;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }
    :global(.BLOCKS__pb-component:hover:after) {
      cursor: pointer !important;
      border: dotted 2px ${theme["color-primary"]} !important;
    }
    :global(.BLOCKS__editable__decoration--hover:hover:enabled) {
      text-decoration: underline;
      text-decoration-line: underline;
      text-decoration-style: initial;
      text-decoration-color: ${theme["color-primary"]};
      text-decoration-thickness: 1px;
    }
    :global(.BLOCKS__link__decoration--hover) {
      color: ${theme["color-primary"]};
    }
    :global(.BLOCKS__link__decoration--hover:hover:enabled) {
      color: ${theme["color-primary"]};
      text-decoration: underline;
      text-decoration-line: underline;
      text-decoration-style: initial;
      text-decoration-color: initial;
    }
    :global(.BLOCKS__highlight) {
      background-color: ${theme["color-highlight"]};
    }

    // =========== VIP ===========
    // - Controls the on page buttons visual appearance
    // - Ensure the button is visible based on colors and contrast
    // - Breaking this would be a critical failure
    // ===========================

    :global(button.BLOCKS__button--primary),
    :global(a.BLOCKS__button--primary) {
      color: ${COLORS.white};
      border-color: ${theme["bg-primary--fade"]};
      background-color: ${theme["bg-primary--fade"]};
    }
    :global(button.BLOCKS__button--primary:hover:enabled),
    :global(a.BLOCKS__button--primary:hover:enabled) {
      background-color: ${theme["color-primary"]};
      border-color: ${theme["color-primary"]};
    }
    :global(button.BLOCKS__button--primary:focus),
    :global(a.BLOCKS__button--primary:focus) {
      border-color: ${theme["b-primary"]};
    }
    :global(button.BLOCKS__button--secondary),
    :global(a.BLOCKS__button--secondary) {
      color: ${theme["color-primary"]};
      border-color: ${theme["b-primary"]};
      background-color: ${COLORS.white};
    }
    :global(button.BLOCKS__button--secondary:hover:enabled),
    :global(a.BLOCKS__button--secondary:hover:enabled) {
      background-color: ${COLORS.whitesmoke};
    }
    :global(button.BLOCKS__button--secondary:focus),
    :global(a.BLOCKS__button--secondary:focus) {
      border-color: ${theme["b-primary"]};
    }
    :global(button.BLOCKS__button--secondary:disabled),
    :global(a.BLOCKS__button--secondary:disabled) {
      cursor: not-allowed;
      border-color: inherit;
    }
    :global(button.BLOCKS__button--primary:disabled),
    :global(a.BLOCKS__button--primary:disabled),
    :global(button.BLOCKS__button--secondary:disabled),
    :global(a.BLOCKS__button--secondary:disabled) {
      cursor: not-allowed;
      color: ${COLORS.disabledAction};
      background-color: ${COLORS.disabled};
      border-color: ${COLORS.disabledActionBorder};
    }
  `;
};
