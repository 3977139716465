import React, { Fragment } from "react";
/**
 * Can check script loading within:
 * - dev tools > sources > page > top
 * @param {Object} params
 * @param {String} params.token
 * @returns
 */
const RewardfulTag = ({ token }) => {
  return (
    <Fragment>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful')`
        }}
      />
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          const script = document.createElement('script');
          script.src = 'https://r.wdfl.co/rw.js';
          script.setAttribute('data-rewardful', '${token}')
          script.setAttribute('data-integration', 'rewardful')
          script.async = true;
          document.head.appendChild(script);
          `
        }}
      />
    </Fragment>
  );
};

export default RewardfulTag;
