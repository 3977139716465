import "react-datepicker/dist/react-datepicker.css";
import "components/Form/react-datepicker-overrides.css";
import "react-toggle/style.css";
import "rc-checkbox/assets/index.css";
import "react-phone-number-input/style.css";
import React, { Fragment } from "react";
import Head from "next/head";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { useStore } from "src/reducers";
import { ViewportProvider } from "utils/context";
import { ToastProvider } from "react-toast-notifications";
import { getBrandTheme } from "utils/theme";
import get from "lodash/get";
import { getConfig } from "utils/env";
import * as Sentry from "@sentry/react";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import { getAppStyles } from "constants/styles/app";
import { getFormStyles } from "constants/styles/form";
import { getOverrideStyles } from "constants/styles/overrides";
import { getBlocksStyles } from "constants/styles/blocks";
import { getShimmerStyles } from "constants/styles/shimmer";
import { getServiceIntegration } from "utils/integration";
import Rewardful from "components/ThirdParty/Rewardful";
import SERVICES from "utils/constants/services";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    dsn: getConfig("SENTRY_DSN"),
    ignoreErrors: [
      "Non-Error exception captured",
      "Non-Error promise rejection captured"
    ],
    integrations: [
      new TracingIntegrations.BrowserTracing({
        tracingOrigins: [getConfig("API_ROOT")],
        beforeNavigate: (context) => {
          return {
            ...context,
            // You could use your UI's routing library to find the matching
            // route template here. We don't have one right now, so do some basic
            // parameter replacements.
            name: window.location.pathname
              .replace(/\d+/g, "<digits>")
              .replace(/[a-f0-9]{32}/g, "<hash>")
          };
        }
      })
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1
  });
}

const App = ({ Component, pageProps }) => {
  const store = useStore(pageProps.initialReduxState);
  const themeInput = get(pageProps, "theme.data", {});
  const brandTheme = getBrandTheme(themeInput);

  const rewardful = getServiceIntegration(
    pageProps && pageProps.integrations,
    SERVICES.REWARDFUL
  );
  const showRewardful = Boolean(rewardful && rewardful.value);

  const { styles: appStyles } = getAppStyles(brandTheme);
  const { styles: formStyles } = getFormStyles(brandTheme);
  const { styles: overrideStyles } = getOverrideStyles(brandTheme);
  const { styles: blocksStyles } = getBlocksStyles(brandTheme);
  const { styles: shimmerStyles } = getShimmerStyles();

  return (
    <Fragment>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        {showRewardful && <Rewardful token={rewardful.value} />}
      </Head>
      <ViewportProvider>
        <ToastProvider placement="bottom-left">
          <Provider store={store}>
            <Component {...pageProps} />
          </Provider>
        </ToastProvider>
      </ViewportProvider>
      {appStyles}
      {formStyles}
      {overrideStyles}
      {blocksStyles}
      {shimmerStyles}
    </Fragment>
  );
};

App.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.shape({
    integrations: PropTypes.arrayOf(
      PropTypes.shape({
        uid: PropTypes.string,
        value: PropTypes.string
      })
    ),
    manifest: PropTypes.shape({
      id: PropTypes.string,
      uuid: PropTypes.string,
      version: PropTypes.string,
      data: PropTypes.shape({
        config: PropTypes.shape({
          pricing: PropTypes.object,
          features: PropTypes.object
        }),
        layout: PropTypes.array,
        entities: PropTypes.array,
        content: PropTypes.object,
        admin: PropTypes.object,
        data: PropTypes.object
      })
    }),
    theme: PropTypes.shape({
      id: PropTypes.string,
      uuid: PropTypes.string,
      version: PropTypes.string,
      data: PropTypes.shape({
        font: PropTypes.shape({
          family: PropTypes.string
        }),
        text: PropTypes.shape({
          primary: PropTypes.string,
          secondary: PropTypes.string
        }),
        color: PropTypes.shape({
          primary: PropTypes.string,
          secondary: PropTypes.string,
          danger: PropTypes.string
        })
      })
    }),
    config: PropTypes.shape({
      route: PropTypes.string,
      subdomain: PropTypes.string
    }),
    initialReduxState: PropTypes.object
  })
};

export default App;
