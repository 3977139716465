import css from "styled-jsx/css";
import {
  MAX_WIDTH,
  DESKTOP,
  PHONE,
  SPLIT_CONTAINER_SECTION_MAX_WIDTH,
  PB_COMPONENT_CLASS
} from "utils/constants/ui";
import { COLORS } from "utils/styles";
import { getFontFamilyUrl, isSystemFont } from "utils/theme";

export const getAppStyles = (theme) => {
  const fontUrl = !isSystemFont(theme["font-family"])
    ? getFontFamilyUrl({
        family: theme["font-family"]
      })
    : "";

  return css.resolve`
  @import url("${{ fontUrl }}");
  :global(html),
  :global(body) {
    margin: 0;
    width: 100%;
    height: 100%;
    touch-action: manipulation;
    color: ${theme["color-text--primary"]};
    box-sizing: border-box;
    font-family: ${
      theme["font-family"]
    }, system-ui, -apple-system, BlinkMacSystemFont;
    // Prevent grey overlay on touch
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-font-smoothing: antialiased;
  }

  :global(select:hover) {
    background: ${COLORS.whitesmoke};
  }
  :global(.cursor-not-allowed) {
    cursor: not-allowed !important;
  }

  :global(.br-6) {
    border-radius: 6px;
  }
  :global(.brbl-6) {
    border-bottom-left-radius: 6px;
  }
  :global(.brbr-6) {
    border-bottom-right-radius: 6px;
  }
  :global(.brtl-6) {
    border-top-left-radius: 6px;
  }
  :global(.brtr-6) {
    border-top-right-radius: 6px;
  }

  :global(.shadow--common) {
    box-shadow: 0 0 0 1px #e0e0e0,0 2px 4px 0 rgba(0,0,0,.07),0 1px 1.5px 0 rgba(0,0,0,.05);
    transition: box-shadow .08s ease-in,color .08s ease-in,filter 50000s;
  }

  // =========== APP
  :global(.layout) {
    width: 100%;
    color: ${theme["color-text--primary"]};
  }
  :global(code) {
    font-family: monospace;
  }
  :global(.color-text--primary) {
    color: ${theme["color-text--primary"]};
  }
  :global(.color-text--secondary) {
    color: ${theme["color-text--secondary"]};
  }
  :global(.b-primary) {
    border-color: ${theme["color-primary"]};
  }
  :global(.color-primary) {
    color: ${theme["color-primary"]};
  }
  :global(.feature__enabled--checkmark) {
    color: ${theme["bg-primary"]} !important;
  }
  :global(.color-error) {
    color: ${theme["color-error"]};
  }
  :global(.color-warning) {
    color: ${COLORS.warning05Text};
  }
  :global(.b--error) {
    border-color: ${COLORS.dangerBorder} !important;
  }
  :global(.b--warning) {
    border-color: ${COLORS.warning} !important;
  }
  :global(.b--success) {
    border-color: ${COLORS.successGreen} !important;
  }
  :global(.bg-primary) {
    background-color: ${theme["bg-primary"]};
  }
  :global(.bg-white--hover:hover) {
    background-color: ${COLORS.whitesmoke};
  }
  :global(.color-transparent) {
    color: transparent;
  }
  :global(.currency-select-min-width) {
    min-width: 5rem;
  }
  :global(.min-w3) {
    min-width: 4rem;
  }
  :global(.min-w3-5) {
    min-width: 7rem;
  }
  @media (max-width: ${PHONE + 1}px) {
    :global(.w-100-s) {
      width: 100%;
    }
    :global(.dn-imp-s) {
      display: none !important;
    }
    :global(.-mt2-s) {
      margin-top: -0.5rem;
    }
  }
  
  :global(.bg-primary--hover) {
    background-color: ${theme["bg-primary--hover"]};
  }
  :global(.bg-primary--fade) {
    background-color: ${theme["bg-primary--fade"]};
  }
  :global(.b--primary) {
    border-color: ${theme["b-primary"]};
  }
  :global(.ba--primary) {
    border-color: ${theme["b-primary"]};
    border-width: 1px;
    border-style: solid;
  }
  :global(.ba--primary--dotted:hover) {
    border: dotted 2px ${theme["b-primary"]};
    margin-left: -2px;
    margin-right: -2px;
  }
  :global(.${PB_COMPONENT_CLASS}) {
    cursor: pointer !important;
  }
  :global(.${PB_COMPONENT_CLASS}:hover),
  :global(.${PB_COMPONENT_CLASS}--focus) {
    outline: none;
    background: rgb(50 151 211 / 5%);
    box-shadow: 0 0 0 1px rgb(50 151 211 / 10%), 0 1px 1px 0 rgb(0 0 0 / 10%), 0 0 0 4px rgb(50 151 211 / 10%) !important;
    border-radius: 4px
  }
  :global(.ba--primary--hover:hover) {
    border-color: ${theme["b-primary"]};
    border-width: 1px;
    border-style: solid;
  }
  :global(.editable__decoration--hover:hover) {
    text-decoration: underline !important;
    text-decoration-line: underline !important;
    text-decoration-style: initial !important;
    text-decoration-color: ${theme["color-primary"]};
    text-decoration-thickness: 1px;
  }
  :global(.link__underline:hover) {
    text-decoration: underline !important;
    text-decoration-line: underline !important;
    text-decoration-style: initial !important;
    text-decoration-color: ${theme["color-text--primary"]};
    text-decoration-thickness: 1px;
  }
  :global(.dotted__underline:hover) {
    text-decoration: underline !important;
    text-decoration-line: underline !important;
    text-decoration-style: dotted !important;
    text-decoration-color: ${theme["bg-primary--fade"]} !important;
    text-decoration-thickness: 1px;
    color: ${theme["bg-primary--fade"]} !important;
  }
  :global(p > a) {
    color: ${COLORS.blue};
    font-weight: 500;
    text-decoration: none;
  }
  :global(.tdn) {
    text-decoration: none !important;
  }
  :global(.link__decoration--hover) {
    color: ${theme["bg-primary--fade"]};
  }

  :global(.link__decoration--hover:hover),
  :global(p > a:hover) {
    color: ${theme["bg-primary"]}
    text-decoration: underline !important;
    text-decoration-line: underline !important;
    text-decoration-style: initial !important;
    text-decoration-color: initial !important;
  }

  :global(.decoration--hover:hover) {
    text-decoration: underline !important;
    text-decoration-line: underline !important;
    text-decoration-style: initial !important;
    text-decoration-color: initial !important;
  }

  :global(button.button--primary),
  :global(a.button--primary) {
    color: ${COLORS.white};
    border-color: ${theme["bg-primary--fade"]};
    background-color: ${theme["bg-primary--fade"]};
  }
  :global(button.button--primary:hover:enabled),
  :global(a.button--primary:hover:enabled) {
    background-color: ${theme["color-primary"]};
    border-color: ${theme["color-primary"]};
  }
  :global(button.button--primary:focus),
  :global(a.button--primary:focus) {
    border-color: ${theme["b-primary"]};
  }

  :global(button.button--secondary),
  :global(a.button--secondary) {
    color: ${theme["color-primary"]};
    border-color: ${theme["b-primary"]};
    background-color: ${COLORS.white};
  }
  :global(button.button--secondary:hover:enabled),
  :global(a.button--secondary:hover:enabled) {
    background-color: ${COLORS.whitesmoke};
  }
  :global(button.button--secondary:focus),
  :global(a.button--secondary:focus) {
    border-color: ${theme["b-primary"]};
  }
  
  :global(button.button--primary:disabled),
  :global(a.button--primary:disabled),
  :global(button.button--secondary:disabled),
  :global(a.button--secondary:disabled),
  :global(a.disabled-link-button--primary),
  :global(a.disabled-link-button--secondary) {
    cursor: not-allowed;
    border-color: inherit;
    color: ${COLORS.disabledAction};
    background-color: ${COLORS.disabled};
    border-color: ${COLORS.disabledActionBorder};
  }
  :global(.token-monospace) {
    font-family: monospace;
    font-size: 12px;
  }
  :global(.bg-whitesmoke) {
    background-color: ${COLORS.whitesmoke};
  }
  :global(.bg-off-white) {
    background-color: #FBFBFB;
  }
  :global(.bg-danger-05) {
    color: ${COLORS.danger05Text};
    background-color: ${COLORS.danger05};
  }
  :global(.bg-warning-05) {
    color: ${COLORS.warning05Text};
    background-color: ${COLORS.warning05};
  }
  :global(.bg-success-05) {
    color: #007217;
    background-color: ${COLORS.success05};
  }
  :global(.bg-gold-05) {
    color: ${COLORS.gold05Text};
    background-color: ${COLORS.gold05};
  }
  :global(.bg-purple-05) {
    color: #7000FF;
    background-color: #EBDCFF;
  }
  :global(.bg-blue-05) {
    color: ${COLORS.blue};
    background-color: ${COLORS.sky_blue};
  }
  :global(.auth__footerLink--dot::before) {
    content: "•";
    margin: 0px 8px;
    margin: 0px 8px;
    color: ${COLORS.textSecondary};
  }
  :global(select) {
    background: white;
    cursor: pointer;
  }
  :global(textarea) {
    resize: none;
  }
  :global(input),
  :global(select),
  :global(textarea) {
    outline: none;
  }
  
  :global(.outline-0) {
    outline: none;
  }
  :global(.brl2--top) {
    border-top-left-radius: .25rem;
  }
  :global(.mw-app) {
    max-width: ${MAX_WIDTH}px;
  }
  :global(.word-break) {
    word-break: break-word;
  }
  :global(.word-break-all) {
    word-break: break-all;
  }
  :global(.white-space-nowrap) {
    white-space: nowrap;
  }
  :global(.cursor-grab) {
    cursor: grab;
  }
  :global(.cursor-auto) {
    cursor: auto;
  }
  :global(.cursor-move) {
    cursor: move;
  }
  :global(.cursor-not-allowed) {
    cursor: not-allowed;
  }
  :global(.pointer-default) {
    cursor: default;
  }
  
  :global(::-webkit-scrollbar) {
    width: 0;
    background: transparent;
  }
  :global(.bg-content--secondary) {
    background-color: ${COLORS.content.secondary};
  }
  :global(.bg-content--hover:hover) {
    background-color: ${COLORS.content.hover};
  }
  :global(.bh--inset) {
    box-shadow: rgba(0, 0, 0, 0.07) -1px 0px inset;
  }
  :global(.bh--inset--hover:hover) {
    box-shadow: rgba(0, 0, 0, 0.07) -1px 0px inset;
  }
  :global(.b-inner--primary) {
    box-shadow: 0px 0px 0px 1px ${theme["color-primary"]};
  }
  :global(.b-inner--primary-fade) {
    box-shadow: 0px 0px 0px 1px ${COLORS.blueFade};
  }
  :global(.sticky) {
    position: sticky;
  }
  @media (min-width: ${DESKTOP}px) {
    :global(.sticky-l) {
      position: sticky;
    }
    :global(.top-3-l) {
      top: 6.5rem;
    }
  }
  :global(.move) {
    cursor: -webkit-move;
    cursor: move
  }
  :global(.user-select-n) {
    user-select: none;
  }
  :global(.hidden) {
    visibility: hidden;
  }
  :global(textarea) {
    -webkit-appearance: none;
  }
  // TYPOGRAPHY
  // F1 is 3
  // F2 is 2.25
  // F3 is 1.5
  // F4 is 1.25
  // F5 is 1
  :global(.f2-5) {
    font-size: 1.875rem;
  }
  :global(.f4-5) {
    font-size: 1.125rem;
  }

  :global(.z-6) {
    z-index: 6;
  }
  :global(.z-7) {
    z-index: 7;
  }
  :global(input:disabled),
  :global(select:disabled),
  :global(textarea:disabled) {
    -webkit-text-fill-color: rgba(51, 51, 51, 0.8);
    -webkit-opacity: 1;
    cursor: not-allowed;
    background: ${COLORS.disabled};
  } 
  :global(.bg-disabled) {
    background: ${COLORS.disabled};
  }
  :global(#__next) {
    height: 100%;
  }
  :global(.mw-0) {
    min-width: 0;
  }
  :global(.shadow-0) {
    -webkit-box-shadow: inset 0px 4px 8px -3px rgba(17, 17, 17, 0.06);
    -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  }
  :global(.hairline-2) {
    border-color: rgba(0, 0, 0, 0.2);
    border-width: 1px;
  }
  :global(.hairline-0) {
    border-color: rgba(0, 0, 0, 0.025);
    border-width: 1px;
  }
  :global(.brn) {
    border-right: none;
  }
  @media (min-width: ${PHONE + 1}px) {
    :global(.brn-ns) {
      border-right: none;
    }
  }
  @media (min-width: ${PHONE + 1}px) {
    :global(.bg-off-white-ns) {
      background-color: #FDFDFD;
    }
  }
  @media (min-width: ${PHONE + 1}px) {
    :global(.split__container-ns) {
      box-shadow: 15px 0 30px 0 rgb(0 0 0 / 18%);
    }
    :global(.split__container--section-ns) {
      max-width: ${SPLIT_CONTAINER_SECTION_MAX_WIDTH}px;
    }
  }
  :global(.w2-5) {
    width: 3rem;
  }
  :global(.h5-5) {
    height: 24rem;
  }
  :global(.hairline-1) {
    border-color: rgba(0, 0, 0, 0.08);
    border-width: 1px;
  }
  :global(.hairline-1-dashed) {
    border-color: rgba(0, 0, 0, 0.08);
    border-style: dashed;
    border-width: 1px;
  }
  :global(.hairline-1-dashed-primary) {
      border-color: ${COLORS.blue};
      border-style: dashed;
      border-width: 1px;
    }
  @media (max-width: ${PHONE}px) {
    :global(.bt-hairline-1-s) {
      border-top: 1px solid rgba(0, 0, 0, 0.08)
    }

    :global(.hairline-1-s) {
      border-color: rgba(0, 0, 0, 0.08);
      border-style: solid;
      border-width: 1px;
    }

    :global(.shadow-0-s) {
      -webkit-box-shadow: inset 0px 4px 8px -3px rgba(17, 17, 17, 0.06);
      -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
    }
  }
  @media (min-width: ${PHONE + 1}px) {
    :global(.hairline-1-ns) {
      border-color: rgba(0, 0, 0, 0.08);
      border-style: solid;
      border-width: 1px;
    }

    :global(.shadow-0-ns) {
      -webkit-box-shadow: inset 0px 4px 8px -3px rgba(17, 17, 17, 0.06);
      -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
    }

    :global(.bg-transparent-ns) {
      background-color: transparent;
    }

    :global(.bln-ns) {
      border-left: 0;
    }

    :global(.min-vh-100-ns) {
      min-height: 100vh
    }
  }
  @media (min-width: ${DESKTOP}px) {
    :global(.bg-white-l) {
      background-color: ${COLORS.white};
    }
  }
  :global(.b--whitesmoke-hover) {
    border-color: white;
  }
  :global(.b--whitesmoke-hover:hover) {
    border-color: ${COLORS.whitesmoke};
  }
  :global(.ta-man) {
    touch-action: manipulation;
  }
  @media (min-width: ${DESKTOP}px) {
    :global(.f3-5-l) {
      font-size: 30px;
    }
  }
  :global(.flex-shrink-0) {
    flex-shrink: 0;
  }
  :global(.container__float) {
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  }
  :global(.b--transparent) {
    border-color: transparent;
  }
  :global(iframe) {
    width: 100%;
  }
  :global(.bg--black-025) {
    background-color: rgba(0,0,0,0.025)
  }
  :global(.brtl-0) {
    border-top-left-radius:0;
  }
  :global(.brtr-0) {
    border-top-right-radius:0;
  }
  :global(.brbl-0) {
    border-bottom-left-radius:0;
  }
  :global(.brbr-0) {
    border-bottom-right-radius:0;
  }
  :global(.brtl-2) {
    border-top-left-radius:0.25rem;
  }
  :global(.brtr-2) {
    border-top-right-radius:0.25rem;
  }
  :global(.brbl-2) {
    border-bottom-left-radius:0.25rem;
  }
  :global(.brbr-2) {
    border-bottom-right-radius:0.25rem;
  }
  :global(.cursor__move:hover) {
    cursor: move;
  }
  :global(.grecaptcha-badge) {
    display: none;
  }
  :global(.app__action--form) {
    max-width: 400px;
  }
  @media (max-width: 30em) {
    :global(.slide__container) {
      height: 16rem;
    }
  }
  @media (min-width: 30em) and (max-width: 60em) {
    :global(.slide__container) {
      height: 16rem;
    }
  }
  @media (min-width: 60em) {
    :global(.slide__container) {
      height: 16rem;
    }
  }

  @media (max-width: 30em) {
    :global(.useCase__container) {
      height: 2.25rem;
    }
  }
  @media (min-width: 30em) and (max-width: 60em) {
    :global(.useCase__container) {
      height: 2.25rem;
    }
  }
  @media (min-width: 60em) {
    :global(.useCase__container) {
      height: 3.125rem;
    }
  }

  @media (max-width: 30em) {
    :global(.service__container) {
      height: 2.25rem;
    }
  }
  @media (min-width: 30em) and (max-width: 60em) {
    :global(.service__container) {
      height: 2.125rem;
    }
  }
  @media (min-width: 60em) {
    :global(.service__container) {
      height: 2.125rem;
    }
  }

  :global(.paymentMethod__container) {
    height: 2rem;
    width: 3.75rem;
  }

  @media (max-width: 30em) {
    :global(.socialService__container) {
      height: 2.25rem;
    }
  }
  @media (min-width: 30em) and (max-width: 60em) {
    :global(.socialService__container) {
      height: 3rem;
    }
  }
  @media (min-width: 60em) {
    :global(.socialService__container) {
      height: 3rem;
    }
  }

  :global(.slide__image::after),
  :global(.image__cover--after::after) {
    content: "";
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
  }
  :global(path.recharts-tooltip-cursor) {
    fill: whitesmoke;
  }
  :global(.color-usa-red) {
    color: #D22738
  }
  :global(.b--usa-red) {
    border-color: #D22738
  }
  :global(.color-usa-blue) {
    color: #05408E
  }
  :global([contenteditable="true"]) {
    outline: none;
    display: block;
    position: relative;
    cursor: text !important;
  }
  :global([contenteditable="true"]:hover) {
    outline: none;
    display: block;
    position: relative;
    background: whitesmoke;
    cursor: text !important;
  }
  :global([contenteditable="true"]:active),
  :global([contenteditable="true"]:focus) {
    outline: none;
    display: block;
    position: relative;
    background: rgba(59, 130, 246, 0.05);
    cursor: text !important;
  }
  :global(.color__gradient) {
    background: -moz-linear-gradient(bottom,  #FDFDFD 20%, rgba(255,255,255,0) 100%); 
    background: -webkit-linear-gradient(bottom,  #FDFDFD 20%,rgba(255,255,255,0) 100%);
    background: linear-gradient(to top,  #FDFDFD 20%,rgba(255,255,255,0) 100%);
  }
  :global(.token-monospace) {
    font-family: monospace;
  }
  `;
};
