import { omitAndSanitizeParams } from "components/Form/fields/helpers";
import { CUSTOM_INTEGRATION_RAW_DEFAULT } from "./constants/integration/app";
import { PRODUCT_STATUS } from "./constants/product";
import { SERVICES } from "./onboarding";
import { getUUID } from "./uuid";
import orderBy from "lodash/orderBy";
import cloneDeep from "lodash/cloneDeep";
import omit from "lodash/omit";
import { MOCK_UUID } from "./constants/request";
import { FORM_KEY } from "./constants/form";

export const getServiceIntegration = (integrations = [], service) =>
  integrations &&
  integrations.find((integration) => integration.uid === service);

export const getSelectIntegrationScaffold = (uuid = getUUID()) => {
  return {
    key: uuid,
    uuid,
    coupon: "",
    promotion_code: ""
  };
};

export const prepareEditIntegrationInitialValues = (integration) => {
  return {
    id: integration.id,
    uuid: integration.uuid,
    name: integration.name,
    raw: integration.raw,
    // Note: updates require a token param. Only suitable to custom integrations
    token:
      integration.service === SERVICES.CUSTOM ? MOCK_UUID : integration.token
  };
};

export const getCreateIntegrationScaffold = (uuid = getUUID()) => {
  return {
    uuid: uuid,
    // Give it a mock token to satisfy db constraints
    name: "",
    token: MOCK_UUID,
    service: SERVICES.CUSTOM,
    service_uid: getUUID(),
    raw: CUSTOM_INTEGRATION_RAW_DEFAULT
  };
};

export const prepareCreateIntegrationsInitialValues = (uuid) => {
  return {
    integrations: [getCreateIntegrationScaffold(uuid)]
  };
};

export const prepareIntegrationFromValues = (integration) => {
  const result = {
    uuid: integration.uuid || getUUID(),
    name: integration.name,
    token: MOCK_UUID,
    service: integration.service,
    service_uid: integration.service_uid
  };
  /**
   * Sanitize test keys from raw value
   */
  const raw = cloneDeep(integration.raw);
  const rawKeys = ["authentication", "request"];
  for (let index = 0; index < rawKeys.length; index++) {
    const key = rawKeys[index];
    if (raw[key] && raw[key]._test) {
      delete raw[key]._test;
    }
  }
  result.raw = raw;
  return result;
};

export const prepareCreateIntegrations = (integrations) => {
  return omitAndSanitizeParams({
    integrations: integrations.map((values) =>
      prepareIntegrationFromValues(values)
    )
  });
};

export const prepareIntegrationShow = ({ integration }) => {
  const result = {
    id: integration.id,
    uuid: integration.uuid,
    displayName: integration.name,
    service: integration.service,
    type: SERVICES.CUSTOM,
    status: PRODUCT_STATUS.ACTIVE
  };

  return result;
};

export const prepareUpdateIntegration = (initial, current) => {
  return omit(current, [FORM_KEY]);
};

export const prepareIntegrationList = ({ integrations, shouldOrder }) => {
  const ctxModels = shouldOrder
    ? orderBy(integrations, ["created_at"], ["desc"])
    : integrations;

  return ctxModels.map((integration) => {
    return prepareIntegrationShow({
      integration
    });
  });
};
