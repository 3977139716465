import css from "styled-jsx/css";

export const getShimmerStyles = () => {
  return css.resolve`
    @keyframes placeHolderShimmer {
      0% {
        background-position: -468px 0;
      }
      100% {
        background-position: 468px 0;
      }
    }
    :global(.shimmer) {
      color: #eeeeef;
      animation-duration: 2s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
      background: #f6f7f8;
      background: linear-gradient(
        90deg,
        #d5d5d5 10%,
        #f5f5f5 50%,
        #d5d5d5 100%
      );
      background-size: 800px 104px;
      position: relative;
    }
  `;
};
